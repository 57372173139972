const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next')
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    siteId: '5ef9e3da4fc7ef7d89cc3157',
    enableExperimentalBlocks: true,
    disabledComponents: ['wButtonLike'],
    metadatas: {
      name: `95 Décibels`,
      colorPrimary: '#e85512'
    },
    analytics: {
      mixpanel: 'a5521d204e99e4207a4c8ead5fd282e5',
      ga: 'UA-111736601-42'
    },
    filterVisibleFilters({ page }) {
      return (filter) => {
        if (filter.slug === 'page') {
          return false;
        } else if (filter.slug === 'format' && page === 'archives') {
          return false;
        }
        return true;
      };
    },
    pages: (pager) => {
      return pager
        .remove('radios')
        .remove('podcasts')
        .update('default', {
          title: 'Vos derniers podcasts',
          visibleInMenu: true,
          icon: 'mic',
          options: {
            filter: true,
            highlighted: true,
            payload: {
              is: 'podcast',
              tags: ['5f1ea4390fc1bc488b6f460a']
            }
          }
        })
        .add({
          title: 'Archives',
          slug: 'archives',
          icon: 'video_library',
          options: {
            filter: true,
            highlighted: false,
            payload: {
              is: 'podcast',
              tags: ['5f1ea4420fc1bc7e736f460b']
            }
          },
          router: {
            path: '/',
            mode: 'archives'
          }
        })
    }
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
